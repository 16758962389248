import React from "react";

const Navbar = () => {
  return (
    <>
      <h1 style={{ textAlign: "end", padding: "2rem" }}></h1>
    </>
  );
};

export default Navbar;
