import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ImageGroup, Image as FullscreenImage } from 'react-fullscreen-image';
import { Divider } from "@mui/material";

const ImageModal = ({ open, onClose, imgObj }) => {
  if (!imgObj || Object.keys(imgObj).length === 0) {
    return (
      <Modal
        open={open}
        onClose={onClose}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: "50%",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" component="h2">
            No Images Available
          </Typography>
          <Typography variant="body1">
            There are no images available for this site.
          </Typography>
        </Box>
      </Modal>
    );
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          width: "80%",
          maxHeight: "80%",
          overflow: "auto",
        }}
      >
        <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>
          Site Photographs
        </Typography>
        <Divider sx={{ my: 2, borderColor: 'rgba(0, 0, 0, 0.8)' }} />
        <Box sx={{ mt: 2 }}>
          {Object.keys(imgObj).map((fieldName) => (
            <div key={fieldName}>
              <Typography   variant="subtitle1" sx={{ mt: 2,fontWeight: 'bold', fontSize: '1.25rem', marginBottom: '10px' }}>{fieldName}:</Typography>
              <ImageGroup>
                <ul className="images" style={{ listStyleType: 'none', padding: 0, display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                  {imgObj[fieldName].map((imgUrl, index) => (
                    <li key={index} style={{ width: 'calc(33.33% - 10px)', position: 'relative', paddingBottom: 'calc(33.33% - 10px)' }}>
                      <FullscreenImage
                        src={imgUrl}
                        alt={`${fieldName} ${index + 1}`}
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          height: '100%',
                          width: '100%',
                          objectFit: 'cover',
                          cursor: 'pointer',
                        }}
                      />
                    </li>
                  ))}
                </ul>
              </ImageGroup>
            </div>
          ))}
        </Box>
      </Box>
    </Modal>
  );
};

export default ImageModal;
