import React, { useEffect, useState } from "react";
import Layout from "../../layout/Main/Layout";
import { Button, MenuItem, Modal, Typography, Box } from "@mui/material";
import CustomTable from "../../components/Custom/Table/CustomTable";
import { get, getBinary, post, put } from "../../config/axios";
import Searchbar from "../../components/Custom/SearchBar/Searchbar";
import DeleteModal from "../../components/Custom/DeleteModal/DeleteModal";
import { toastMessage } from "../../utils/toastMessage";
import { reportsTableColumns } from "../../constants/reportsPage";
import { useDebouncedValue } from "../../helper/debounce";
import AddIcon from "@mui/icons-material/Add";
import style from "./Reports.module.css";
import VisitDateModal from "../../components/Custom/VisitDateModal/VisitDateModal";
import { Navigate } from "react-router-dom";

const Reports = () => {
    const [properties, setProperties] = useState([]);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteUser, setDeleteUser] = useState("");
    const [message, setMessage] = useState("");
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [loading, setLoading] = useState(true);
    const [visitdates, setvisitdates] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [editData, setEditData] = useState({});
    const [propertyType, setPropertyType] = useState([]);
    const [propertiesValue, setPropertiesValue] = useState({
        name: "",
        address: "",
        area: "",
        groupName: "",
        borrowerName: "",
        projectZone: "",
        projectCost: "",
        loanSanctionAmount: "",
        loadPOS: "",
        groupSanctionAmount: "",
        groupPOS: "",
        propertyType: "",
        maturityDate: "",
    });

    const debouncedSearch = useDebouncedValue(search, 2000);

    const fetchProperties = async (searchValue) => {
        await get(`/dashboard/property/getAllProperty?search=${searchValue}&page=${page}&limit=10&admin`)
            .then((res) => {
                setProperties(res?.data.map((item) => ({ ...item, action: { edit: true, delete: false } })));
                setLoading(false);
                setPageCount(res?.totalPage);
            })
            .catch((err) => {
                console.log("err", err);
                setLoading(true);
            });
    };

    useEffect(() => {
        if (search === "") {
            fetchProperties("");
        } else if (debouncedSearch) {
            fetchProperties(debouncedSearch);
        }
    }, [search, debouncedSearch, message, page]);

    const handleDelete = (row) => {
        setDeleteUser(row);
        setDeleteModalOpen(true);
    };


    const handleActive = async (id, active) => {
        let response = await put(`dashboard/property/updateProperty`, {
            _id: id,
            active: active,
        });
        setMessage(`${active}-${response.message}`);
        toastMessage(response.message, "success");
    };

    const handlePageChange = (page) => {
        setPage(page);
    };

    const openModal = (type, dataForEdit) => {
        if (type === "edit") {
            setEditModal(true);
            setEditData({
                ...dataForEdit,
                propertyType: dataForEdit?.propertyType?._id,
            });
            const { action, ...propertyData } = dataForEdit;
            setPropertiesValue({
                ...propertyData,
                propertyType: dataForEdit.propertyType?._id ?? "",
            });
        }
    };

    const closeModal = () => {
        setEditModal(false);
        setEditData({});
    };


    const handleSearch = (searchText) => {
        setSearch(searchText);
        setPage(1);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setPropertiesValue((prevValues) => ({
            ...prevValues,
            projectCost: 0,
            [name]: value,
        }));
    };

    const handleDownload = async (row) => {
        console.log("row", row);
        try {
            setLoading(true);
            console.log(`Requesting PDF for document ID: ${row._id}`);

            const response = await getBinary(`/dashboard/visit/getComparepdf?id=${row._id}`, 'json', {
                'Accept': 'application/json',
            });

            console.log('Response:', response);

            if (!response || !response.pdfBuffer || !response.pdfBuffer.data) {
                 toastMessage(response?.message || 'Something went wrong', 'error');
                console.log('Invalid response format or missing data.');
                throw new Error('Invalid response format or missing data.');
            }

            const bufferData = response.pdfBuffer.data;

            const blob = new Blob([new Uint8Array(bufferData)], { type: 'application/pdf' });
            //console.log('Blob created:', blob);

            const url = window.URL.createObjectURL(blob);
            //console.log('Blob URL:', url);

            const link = document.createElement('a');
            link.href = url;
            const fileName = row.name ? `${row.name}.pdf` : 'document.pdf';
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            console.error('Error downloading the PDF', error);
        } finally {
            setLoading(false);
        }
    };

    const handleView = async (row) => {
        try {
            setLoading(true);
            console.log(`Requesting PDF for document ID: ${row._id}`);

            const response = await getBinary(`/dashboard/visit/getComparepdf?id=${row._id}`, 'json', {
                'Accept': 'application/json',
            });

            //console.log('Response:', response);

            if (!response || !response.pdfBuffer || !response.pdfBuffer.data) {
                toastMessage(response?.message || 'Something went wrong', 'error');
                console.log('Invalid response format or missing data.');
                throw new Error('Invalid response format or missing data.');
            }

            const bufferData = response.pdfBuffer.data;

            // Convert the array buffer to a Blob
            const blob = new Blob([new Uint8Array(bufferData)], { type: 'application/pdf' });
            //console.log('Blob created:', blob);

            // Create a URL for the blob
            const url = window.URL.createObjectURL(blob);
            //console.log('Blob URL:', url);

            // Open the PDF in a new tab for viewing
            window.open(url, '_blank');
        } catch (error) {
            console.error('Error viewing the PDF', error);
        } finally {
            setLoading(false);
        }
    };


    const handleVisitDates = async (row) => {
        console.log("rowid",row._id);
        try {
            setLoading(true);
            const response = await get(`/dashboard/visit/getPreviousVisits?id=${row._id}`)

            
             console.log(response);


            if (!response || !response?.success) {
               
                toastMessage(response?.message || 'Something went wrong', 'error');
       
            }
            else{
                setvisitdates(response.data);
                setIsModalOpen(true);
                toastMessage(response.message, "success");
            }

            //const visitdates = response;
            //console.log(visitdates);
          

        } catch (error) {
            console.log(error)
            alert(error.message)
            console.error('Error fetching dates', error);
            toastMessage('Failed to fetch visit dates', "error");
        
        } finally {
            setLoading(false);
        
        }
    };


    return (
        <Layout>
            <div style={{ padding: "1rem" }}>
                <Typography variant="h5">Compare</Typography>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <div style={{ width: "50%" }}>
                        <Searchbar
                            search={handleSearch}
                            placeholder={"Search"}
                            debounceTime={1000}
                        />
                    </div>

                    <Button
                        onClick={() => openModal("add")}
                        variant="outlined"
                        startIcon={<AddIcon fontSize="large" />}
                        style={{ fontWeight: "bold" }}
                    >
                        Add Report
                    </Button>
                </div>
                <CustomTable
                    data={properties}
                    columns={reportsTableColumns}
                    handlePageChange={(page) => handlePageChange(page)}
                    handleDownload={handleDownload}
                    handleView={handleView}
                    handleVisitDates={handleVisitDates}
                    pageNumber={page}
                    pageCount={pageCount}
                    loading={loading}
                />
            </div>
            <VisitDateModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                data={visitdates}
            />
        </Layout>
    );
};

export default Reports;
