export const reportsTableColumns = [
    {
      id: "S.No",
      label: "S.No",
      minWidth: 70,
      align: "left",
    },
    // {
    //   id: "propertyid",
    //   label: "Property Id",
    //   minWidth: 70,
    //   align: "left",
    // },
    // {
    //   id: "groupName",
    //   label: "Borrower Group Name",
    //   minWidth: 100,
    //   align: "left",
    // },
    {
      id: "name",
      label: "Project Name",
      minWidth: 100,
      align: "left",
    },
    // {
    //   id: "projectZone",
    //   label: "Project Location/ Zone",
    //   minWidth: 70,
    //   align: "left",
    // },
    {
      id: "address",
      label: "Project Address",
      minWidth: 100,
      align: "center",
    },
    // {
    //   id: "projectGeoLocation",
    //   label: "Project GeoLocation",
    //   minWidth: 100,
    //   align: "center",
    // },
    {
      id: "borrowerName",
      label: "Borrower Name",
      minWidth: 100,
      align: "center",
    },
    {
        id: "visitDates",
        label: "Site Visit Dates",
        minWidth: 100,
        align: "center",
      },
    // {
    //   id: "lan",
    //   label: "LAN No.",
    //   minWidth: 100,
    //   align: "center",
    // },
    // {
    //   id: "borrowerMobile",
    //   label: "Borrower Mobile No.",
    //   minWidth: 100,
    //   align: "center",
    // },
  
    // {
    //   id: "borrowerAddress",
    //   label: "Borrower Address",
    //   minWidth: 100,
    //   align: "center",
    // },
    // {
    //   id: "loanSanctionAmount",
    //   label: "Loan Sanction Amount",
    //   minWidth: 100,
    //   align: "center",
    // },
    // {
    //   id: "loadPOS",
    //   label: "Loan POS",
    //   minWidth: 100,
    //   align: "center",
    // },
    // {
    //   id: "groupSanctionAmount",
    //   label: "Group Sanction Amount",
    //   minWidth: 100,
    //   align: "center",
    // },
    // {
    //   id: "groupPOS",
    //   label: "Group POS",
    //   minWidth: 100,
    //   align: "center",
    // },
    // {
    //   id: "propertyType.name",
    //   label: "Project Type",
    //   minWidth: 100,
    //   align: "center",
    // },
    // {
    //   id: "projectCost",
    //   label: "Project Cost",
    //   minWidth: 100,
    //   align: "center",
    // },
    // {
    //   id: "active",
    //   label: "Status",
    //   minWidth: 100,
    //   align: "center",
    // },
    // {
    //   id: "dateOfVisit",
    //   label: "Last visit date",
    //   minWidth: 120,
    //   align: "center",
    // },
    // {
    //   id: "labours",
    //   label: "No. Of labours",
    //   minWidth: 100,
    //   align: "center",
    // },
    // {
    //   id: "action",
    //   label: "Action",
    //   minWidth: 100,
    //   align: "center",
    // },
    {
        id: "download",
        label: "Action",
        minWidth: 70,
        align: "center",
      },
      {
        id: "view",
        label: "View",
        minWidth: 70,
        align: "center",
      },
  ];
  
 